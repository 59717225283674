import React from "react";
import data from "../../roadmap/3p10-details";
import Page from "../../components/Page";
import FeaturesAccordion from "../../components/FeaturesAccordion";
import WithSideNav from "../../components/WithSideNav";
import Section from "../../components/Section";

const roadmapId = "3.10";

function RoadmapPage({ location }) {
  return (
    <Page>
      <Section>
        <WithSideNav sideNavType="community" title="Roadmap">
          <p className="">
            We are excited to share our plans with the community! Below you may
            find an outline of our ongoing projects and major components
            scheduled to be included in upcoming feature versions. We would love
            to hear your feedback on our roadmap and welcome you to join the
            conversation on our
            <span className="text-blue-100">
              <a
                href="https://community.ohif.org/"
                target="_blank"
                rel="noreferrer"
              >
                {" community forum."}
              </a>
            </span>
          </p>
          <div className="mt-2">
            <a href="/roadmap/archived" className="text-blue-100">
              View Archived Roadmaps
            </a>
          </div>
          <div className="flex flex-col gap-1 mt-2">
            <span className="text-blue-100 text-4xl">v3.10</span>
            <span>Expected Release: Q1 2025</span>
          </div>
          <div className="mb-20 mt-15 text-white">
            <FeaturesAccordion
              data={data}
              location={location}
              basePath={`roadmap/${roadmapId}`}
            />
          </div>
        </WithSideNav>
      </Section>
    </Page>
  );
}

export default RoadmapPage;
