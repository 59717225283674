import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const data = [
  {
    id: "UI/UX",
    title:
      "Complete Migration to ohif/ui-next and Deprecation of Legacy OHIF/UI",
    summary: () => (
      <p>
        We are finalizing our UI/UX overhaul by migrating all remaining
        components to the new ohif/ui-next. This update will fully replace the
        outdated OHIF/UI, offering enhanced customization, accessibility, and
        compatibility. Migration guides will be provided to ensure a smooth
        transition.
      </p>
    ),
    description: () => (
      <div className="flex flex-col space-y-5">
        <p className="mt-2">
          In our ongoing effort to modernize and improve the OHIF Viewer,
          version 3.10 will complete the migration of all remaining components
          from the legacy OHIF/UI to the more robust ohif/ui-next, built with
          shadcn/UI and radix UI. While version 3.9 saw the initial migration of
          core elements such as buttons, inputs, sliders, and dropdowns, this
          update will finish the job, deprecating OHIF/UI entirely. The new
          ohif/ui-next framework offers significant advantages over its
          predecessor, including greater customization options, improved
          accessibility features, and full keyboard compatibility, addressing
          previous limitations in our UI design. We’ll provide clear migration
          guides to help you map old components to their new counterparts,
          making the switch seamless and intuitive. With this shift, OHIF Viewer
          users will benefit from a smoother, more responsive interface that
          aligns with modern usability standards.
        </p>
      </div>
    )
  },
  {
    id: "UndoRedoAnnotation",
    title:
      "Implement Undo/Redo Functionality for Annotation and Segmentation Edits",
    summary: () => (
      <p>
        We will introduce undo/redo capabilities for annotation and segmentation
        actions, allowing users to reverse or reapply changes step-by-step. This
        feature will support configurable undo stack depth, with programmatic
        control to enable, disable, or clear the undo/redo history as needed.
      </p>
    ),
    description: () => (
      <div className="flex flex-col space-y-5">
        <p>
          To enhance user control and flexibility, we will implement an API that
          enables undo/redo functionality for annotation and segmentation edits.
          Users will be able to step back through a history of changes,
          reversing or reapplying each action in sequence. Undo/redo actions
          will apply across all annotations and segmentations, even across
          multiple image slices, ensuring that users can track and manage their
          edits comprehensively.
        </p>
        <p>
          The undo/redo functionality will follow standard conventions: users
          can undo multiple actions in succession, and these actions can be
          “redone” unless a new edit is made following an undo, at which point
          the redo history will be cleared. The buffer’s step depth will be
          configurable, allowing applications to set a maximum number of undo
          steps up to 100. Additionally, the undo/redo stack can be
          programmatically enabled, disabled, or cleared, offering developers
          fine-grained control over how this functionality is applied within
          their application.
        </p>
        <p>
          Each undo or redo action will correspond to discrete user
          interactions, defined as changes made from the time a mouse button is
          pressed to the time it is released. The affected image slice will be
          returned after each undo/redo operation, ensuring that users can
          immediately view the result and make adjustments as needed. This
          robust undo/redo capability will provide users with greater confidence
          and flexibility in making and refining annotations and segmentations.
        </p>
      </div>
    )
  },
  {
    id: "3DBrushTool",
    title:
      "3D Brush Tool with Connected Region Filling and Adaptive Thresholding",
    summary: () => (
      <p>
        Introducing a 3D brush tool for voxel-level segmentation, this tool
        enables selective region filling based on grayscale similarity and
        spatial connectivity. Users benefit from interactive previews,
        adjustable parameters, and integration with existing segmentations for
        precise, dynamic 3D editing.
      </p>
    ),
    description: () => (
      <div className="flex flex-col space-y-5">
        <p>
          The new 3D brush tool is designed for highly detailed voxel-level
          segmentation by leveraging grayscale similarity and spatial
          connectivity. Unlike traditional 2D segmentation tools, this 3D brush
          operates on a spherical volume, allowing users to define connected
          regions in 3D space based on similarity to central voxel values. This
          approach ensures selective filling within homogeneous areas, enhancing
          segmentation accuracy.
        </p>
        <p>
          Key functionalities include Voxel Selection by Grayscale Similarity,
          which fills connected voxels only if they share similar grayscale
          values with the central brush area. An Interactive Preview feature
          displays a semi-transparent overlay on targeted voxels when hovering,
          indicating the potential fill area before confirming the action.
        </p>
        <p>
          The tool also includes an Eraser Functionality, enabling users to
          remove overlapping voxels by starting the brush outside segmented
          regions, providing easy refinement of segmented areas. Users can
          adjust Grayscale Range and Brush Diameter, making it adaptable to
          various segmentation needs.
        </p>
      </div>
    )
  },
  {
    id: "SegmentationMetricsAPI",
    title: "API for Retrieving Segmentation Metrics and Statistics",
    summary: () => (
      <p>
        We will develop methods that enable retrieval of detailed segmentation
        metrics, including intensity values, metabolic tumor volume, lesion
        glycolysis, and other critical statistics. This will support both
        individual labelmap segmentations and groups of overlapping
        segmentations, facilitating advanced analysis.
      </p>
    ),
    description: () => (
      <div className="flex flex-col space-y-5">
        <p>
          To support advanced data analysis and enhance the utility of
          segmentation data, we will introduce an API that provides access to a
          range of segmentation metrics. For individual 2D or 3D labelmap
          segmentations, this will allow users to retrieve key statistics,
          including the median, mean, minimum, and maximum intensity values.
          Where applicable, these values will be adjusted using the Modality
          LUT; if not, raw intensity values will be used.
        </p>
        <p>
          Additional metrics will include the standard deviation of intensity
          values, SUV peak (the hottest pixel in a 1cm ROI), volume in
          uncalibrated voxels, and lesion glycolysis (calculated as mean SUV
          multiplied by metabolic tumor volume). For sets of overlapping
          segmentations, the API will provide aggregated statistics, enhancing
          our users' ability to analyze complex segmentation data, particularly
          in research and clinical settings.
        </p>
      </div>
    )
  },
  {
    id: "LabelMapInterpolation",
    title:
      "Implement Interpolation Between Label Map Slices with User-Friendly UI",
    summary: () => (
      <p>
        We will add functionality to interpolate label map segmentations across
        slices, providing users with a smooth, continuous segmentation
        experience. This tool will include a dedicated UI and will leverage
        algorithms from ITK to support interpolation between non-adjacent
        labelmap slices.
      </p>
    ),
    description: () => (
      <div className="flex flex-col space-y-5">
        <p>
          To improve segmentation workflows and reduce manual work, we will
          develop an interpolation tool that fills in label map segmentations
          across unsampled slices. This functionality, based on the established
          ITK algorithm (morphologicalContourInterpolation), will allow users to
          generate intermediate segmentations between two predefined label maps,
          without needing to manually segment each slice.
        </p>
        <p>
          By default, the interpolation will not consider background imaging
          data, in line with 3D Slicer’s current approach. This tool will be
          accessible via a dedicated UI to make it intuitive and efficient for
          users to propagate label maps across unsampled regions, significantly
          reducing time spent on manual segmentations and enabling more
          efficient workflows in both clinical and research settings.
        </p>
      </div>
    )
  },
  {
    id: "WebGPU3DGrowCut",
    title:
      "Implement High-Performance WebGPU-Based 3D Grow Cut Segmentation Toolkit",
    summary: () => (
      <p>
        We will add a WebGPU-accelerated Grow Cut segmentation tool to our
        library, enabling fast, 3D segmentation. This tool will offer both
        manual and automatic modes, allowing users to configure initial regions
        for precise or one-click segmentation.
      </p>
    ),
    description: () => (
      <div className="flex flex-col space-y-5">
        <p>
          To enhance image segmentation capabilities, we will implement a 3D
          Grow Cut segmentation tool within our library, powered by WebGPU for
          high-speed processing. This addition will allow users to segment
          regions in 3D with unprecedented speed, leveraging the parallel
          processing capabilities of WebGPU to handle large data volumes
          efficiently.
        </p>
        <p>
          The tool will support configurable segmentation options to meet
          diverse user needs. In one mode, users will be able to manually select
          positive and negative segments, giving them detailed control over how
          the segmentation grows. In another mode, a one-click option will
          automatically identify initial regions based on predefined criteria,
          providing a faster, more accessible segmentation in PET images that
          requires minimal input.
        </p>
        <p>
          This WebGPU-based implementation will bring powerful, configurable
          Grow Cut segmentation directly into our library, offering both
          flexibility and performance for advanced PET image analysis.
        </p>
      </div>
    )
  },
  {
    id: "AIDrivenPropagation",
    title:
      "Implement AI-Driven Propagation and Auto-Adjustment of Segments Across Slices",
    summary: () => (
      <p>
        We will leverage AI models to enable automatic propagation and
        adjustment of segments across image slices. This functionality will use
        encoder-decoder models such as Segment Anything Model (SAM), allowing
        users to efficiently extend and adapt segmentations slice by slice.
      </p>
    ),
    description: () => (
      <div className="flex flex-col space-y-5">
        <p>
          To streamline segmentation workflows, we will introduce an AI-powered
          tool that propagates and automatically adjusts segments across
          sequential image slices. This tool will allow users to select a
          segment on one slice and propagate it to the next, where it will be
          auto-adjusted to match the underlying anatomy.
        </p>
        <p>
          This functionality will be powered by an AI model such as Meta’s
          Segment Anything Model, running on WebGPU using an ONNX version for
          client-side performance. The model will leverage contextual
          information from the previous slice’s label map to predict and
          generate the adjusted label map on the next slice, providing high
          accuracy in multi-slice segmentation tasks.
        </p>
        <p>
          In addition, we will develop support for other encoder-decoder AI
          models, creating an extensible setup that allows users to bring their
          own ONNX-compatible segmentation models. This setup will be integrated
          into the Cornerstone3D platform, enabling users to easily load and run
          various models, not limited to Segment Anything, for client-side AI
          segmentation.
        </p>
      </div>
    )
  },
  {
    id: "SegmentationMIPOutlines",
    title:
      "Display Segmentation Outlines in Maximum Intensity Projection (MIP) Viewports",
    summary: () => (
      <p>
        We will improve the rendering of segmentation outlines in MIP viewports,
        ensuring that outlines accurately follow the anatomy from all viewing
        angles. This enhancement will provide configurable outline thickness and
        color, as well as interactive selection of segments.
      </p>
    ),
    description: () => (
      <div className="flex flex-col space-y-5">
        <p>
          Currently, segmentation outlines in MIP viewports do not fully conform
          to the anatomy and may appear behind structures in certain rotations.
          To address this, we will refine our rendering approach to account for
          every sample along the ray path, ensuring that outlines correctly wrap
          around the anatomy from any angle in the MIP view. This improved
          rendering method will deliver precise, anatomically accurate
          segmentation outlines in the MIP viewport.
        </p>
        <p>
          In addition, users will have the ability to customize the thickness
          and color of each outline individually, enhancing the clarity of
          lesions or other segmentations in the MIP display. The update will
          also include an interactive "pick" function, allowing users to select
          and highlight specific segmentations within the MIP viewport by
          adjusting their outline color or thickness.
        </p>
        <p>
          This enhancement will bring precise, configurable, and interactive
          segmentation outlines to MIP viewports, significantly improving the
          accuracy and usability of PET/CT visualization for detailed lesion
          analysis.
        </p>
      </div>
    )
  },
  {
    id: "InPlaneBidimensionalMeasurement",
    title:
      "Generate In-Plane Bidimensional Measurement from 3D Segment on Largest Slice",
    summary: () => (
      <p>
        We will create a tool that identifies and places bidimensional
        measurements on the largest in-plane slice of a 3D segment. This
        includes the longest diameter along the lesion’s main axis and the
        longest possible perpendicular diameter within the same slice.
      </p>
    ),
    description: () => (
      <div className="flex flex-col space-y-5">
        <p>
          This tool will enable users to generate precise in-plane bidimensional
          measurements from a 3D segment by identifying the slice with the
          largest lesion area. The utility will determine the longest axis of
          the lesion within this slice and place a measurement along it, then
          place the longest possible perpendicular measurement that stays within
          the contour boundary. This ensures accurate and consistent
          bidimensional assessments, especially useful for tracking lesion size
          changes over time.
        </p>
        <p>
          The resulting measurement will inherit the contour’s color, border
          thickness, and style for seamless visual integration. Additionally,
          the coordinates of the measurement’s vertices, in DICOM pixel space,
          will be accessible programmatically when the associated contour is
          available, facilitating data extraction for reporting and analysis.
        </p>
      </div>
    )
  },
  {
    id: "HoverHighlightSegments",
    title:
      "Enable Hover-Based Highlighting of Individual Segments with Adjustable Border Thickness",
    summary: () => (
      <p>
        We will add functionality to highlight individual segments on hover by
        increasing their border thickness, making it easier for users to
        identify and interact with specific lesions. This enhancement will
        require adjustments to the WebGL shader for dynamic border control.
      </p>
    ),
    description: () => (
      <div className="flex flex-col space-y-5">
        <p>
          To improve usability in working with multiple segments, we will
          introduce a hover-based highlighting feature that visually emphasizes
          individual segments. When a user hovers over a specific segment in a
          labelmap, the segment’s border thickness will increase, drawing
          attention to it as a selected item. This will make it easier for users
          to locate, identify, and interact with specific lesions in complex
          segmentation maps.
        </p>
        <p>
          The highlight effect will be customizable, allowing applications to
          set the border thickness based on specific needs. The highlighting
          will be triggered when the cursor is inside a contour based on the
          winding rule (details to be determined), excluding any holes within
          the contour. This functionality will be implemented by modifying the
          WebGL shader to support variable border thickness per segment.
        </p>
        <p>
          This hover-based highlighting will offer a more intuitive interaction
          model, enhancing the clarity and precision of segment selection in
          annotation workflows.
        </p>
      </div>
    )
  }
];

export default data;
